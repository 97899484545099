.information-message {
  /* max-width: 528px; */
  max-width: 628px;
  height: 100%;

  padding: 22px 24px 22px 16px;

  display: flex;
  align-items: start;
  gap: 12px;

  border-radius: 4px;
  background: #578fff1a;
}
