$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts/icons" !default;

$icon-edit: "\e900";
$icon-user: "\e901";
$icon-plus: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-left: "\e904";
$icon-link: "\e905";
$icon-drop-arrow: "\e906";
$icon-search: "\e907";
$icon-Facebook: "\e908";
$icon-Instagram: "\e909";
$icon-LinkedIn: "\e90a";
$icon-Twitter: "\e90b";
$icon-YouTube: "\e90c";

