@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

body {
  font-size: 14px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;

  .text-bg-danger {
    background-color: rgba(195, 38, 4, 0.6) !important;
  }

  .text-bg-success {
    background-color: rgba(4, 195, 92, 0.8) !important;
  }
}

h3 span {
  font-size: 14px;
  color: #8e8ea8;
  text-shadow: 1px 1px 2px rgba(142, 142, 168, .2);
}

.btn-withdrawal {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .75rem;
}

.tooltipArea {
  position: absolute;
  z-index: 10;
  font-size: 12px;
  width: 250px;
  margin-left: -225px;
  margin-top: -115px;
  background-color: rgba(0, 0, 0, 0.80);
  padding: 5px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  font-weight: 400;
  text-align: center;
}

.dropdown button:focus + .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}

.tooltipArea {
  display: none;
}

.tooltipItem img:hover + .tooltipArea {
  display: block;
}

.form-control, .form-select {
  font-size: 14px;
}

.form-select {
  width: 90%;
}

.form-control {
  display: inline-block;
  width: 90%;

  &:focus {
    box-shadow: none;
  }
}

table td, table th {
  white-space: pre;
}

table td img, table th img {
  max-width: unset;
}

.between {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 0 15px;
  width: 100%;
  max-width: 1342px;
  margin: 0 auto;
}

.page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #181833;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.recharts-responsive-container {
  min-height: 400px;
}

.text-bg-stopped {
  color: #DC3545;
}

.green-btn {
  padding: 10px 26px;
  cursor: pointer;
  border: 0;
  background-color: #04C35C;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: .7;
  }
}

.cancel-btn {
  padding: 11px 34px;
  border-radius: 20px;
  border: 1px solid rgba(24, 24, 51, 0.1);
  cursor: pointer;
  transition: all .3s ease-in-out;
  color: #181833;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    opacity: .7;
    color: #181833;
  }
}

.me-1 {
  @media (max-width: 767px) {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //margin-left: 15px;
  }
}
