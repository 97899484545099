@import "../base/variables";

.actions {
  display: flex;
  align-items: center;
  grid-gap: 16px;

  @media #{$phone} {
    grid-gap: 10px;
    width: 100%;
    justify-content: space-between;
  }
}

.page-top {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(24, 24, 51, 0.1);
  margin-bottom: 12px;

  &__wrap {
    align-items: center;

    @media #{$phone} {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 10px;
    }
  }
}

.p-table {
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(24, 24, 51, 0.1);
  margin-bottom: 16px;
  overflow: auto;

  table {
    width: 100%;
    min-width: 736px;
  }

  th, td {
    padding-bottom: 26px;
    @extend %g-medium;
  }

  th {
    @extend %g-medium;
    font-size: 14px;
    line-height: 30px;
    color: rgba(24, 24, 51, 0.6);
  }

  .txt-col {
    font-size: 16px;
    line-height: 30px;
    color: rgba(24, 24, 51, 0.7);

    &.text-bg-success {
      color: rgba(4, 195, 92, 0.7) !important;
      background-color: transparent !important;
    }

    [class*='icon'] {
      font-size: 16px;
      color: rgba(24, 24, 51, 0.7);
    }
  }

  .token-it {
    display: flex;
    align-items: center;
    grid-gap: 15px;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}

.t-data {
  padding-bottom: 48px;
}

.info {

  &__title {
    @extend %g-semi-bold;
    font-size: 20px;
    line-height: 25px;
    color: #181833;
    padding-bottom: 13px;
  }

  &__desc {
    @extend %g-medium;
    font-size: 14px;
    line-height: 169%;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 44px;
    max-width: 528px;
  }
}

.info-table {
  overflow: auto;

  table {
    width: 100%;
    max-width: 800px;
    min-width: 650px;
  }

  th, td {
    @extend %g-medium;
    font-size: 14px;
    line-height: 17px;
    color: rgba(24, 24, 51, 0.6);
    padding-bottom: 21px;
  }

  td {
    color: #000000;
  }
}

.t-checkbox {

  input {
    display: none;

    &:checked {
      & + label {
        &::after {
          transform: scale(1);
        }
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    position: relative;
    grid-gap: 8px;
    cursor: pointer;

    &::after {
      @extend %transition;
      content: '';
      position: absolute;
      top: 6px;
      left: 5px;
      width: 10px;
      height: 8px;
      background: url("../../icons/check.svg") center no-repeat;
      transform: scale(0);
    }

    &::before {
      content: '';
      border: 1px solid rgba(116, 116, 133, 0.2);
      border-radius: 2px;
      width: 20px;
      height: 20px;
      min-width: 20px;
    }

    .line {
      width: 16px;
      height: 4px;
      border-radius: 2px;

      &.blue {
        background-color: #5790FF;
      }

      &.green {
        background-color: #04C35C;
      }

      &.yellow {
        background-color: #F3BA2F;
      }

      &.red {
        background-color: #C32604;
      }
    }

    .txt {
      @extend %g-semi-bold;
      color: #000000;
    }
  }
}

.graph {
  min-height: 400px;
  padding: 16px 0 32px;
  background-color: #F9F9F9;
}
