@import "../base/variables";

.profile-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  font-family: 'Gilroy', sans-serif;
  padding: 30px 0;
  border-radius: 16px;
  border: 1px solid rgba(24, 24, 51, 0.1);
  background: #FFF;

  &.strategy {
    max-width: 550px;
  }

  .form-select {
    width: 100%;
  }

  &__right {
    opacity: .5;
  }

  &__title {
    color: #181833;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
  }

  &__wrap {
    display: flex;
    gap: 64px;
    width: 100%;
    border-bottom: 1px solid rgba(24, 24, 51, 0.1);
    padding: 0 30px 34px;

    @media #{$phone} {
      gap: 30px;
      flex-wrap: wrap;
      padding: 0 15px 20px;
    }
  }

  &__left, &__right {
    width: 100%;
  }

  &__it {
    width: 100%;

    &:not(:last-child) {
      padding-bottom: 26px;
    }

    label {
      color: #181833;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 9px;
    }

    input {
      width: 100%;
      padding: 11px 15px;
      line-height: 1;
    }

    .form-select {
      padding: 9px 15px;
    }
  }
}
